$(document).ready(function (e) {
  // initPrjTypeCb();
  hideRemoveElement("remove-extra-fee-item", 0);

  $(".toggle-project-show").click(function (e) {
    e.preventDefault();

    if ($(".project-confirm-item").hasClass("show-full")) {
      $(".project-confirm-item").removeClass("show-full");
    } else {
      $(".project-confirm-item").addClass("show-full");
    }
  });

  $(".project-form.project-form-js .switch-prj-mode .btn-switch").click(function (e) {
    e.preventDefault();
    if ($(this).hasClass("disabled")) {
      return false;
    }
    let index = $(this).data("prj-mode") === "term" ? 0 : 1;
    let startDate = $("#project_start_date")
    let termTab = $(".project-form.project-form-js .switch-prj-mode .btn-switch[data-prj-mode='term']");
    let spotTab = $(".project-form.project-form-js .switch-prj-mode .btn-switch[data-prj-mode='spot']");
    if (index === 0) {
      $(".js-project-date-block").find("input").prop("disabled", false).prop("readonly", false);
      $("#project_project_type").val("term");
      startDate.prop("required", false);
      // get sibling label of project_start_date and remove span required inside label
      startDate.siblings("label").find("span").remove();
        if (!termTab.hasClass("active")) {
            termTab.addClass("active");
        }
        if (spotTab.hasClass("active")) {
            spotTab.removeClass("active");
        }
    } else {
      $(".js-project-date-block").find("input").prop("disabled", true).prop("readonly", true);
      $("#project_project_type").val("spot");
      startDate.prop("required", true);
      // get sibling label of project_start_date and add span required inside label if not exist
      if (startDate.siblings("label").find("span").length === 0) {
          startDate.siblings("label").append("<span class=\"text-danger\">（必須）</span>");
      }
      if (!spotTab.hasClass("active")) {
        spotTab.addClass("active");
      }
      if (termTab.hasClass("active")) {
        termTab.removeClass("active");
      }
    }
  });

  // hide elements has project-external-fields class when prj-type-cb checkbox is checked
  $("#prj-type-cb").change(function () {
    $(".project-external-fields").toggleClass("d-none");
    const site = $("#project_site")
    const station = $("#project_station")
    // toggle required attribute for site and station fields
    site.prop("required", !site.prop("required"));
    station.prop("required", !station.prop("required"));
  });

  $('.list-drivers .driver-item input[type="checkbox"]').change(function (e) {
    e.preventDefault();

    let maxPick = parseInt($("#MaxPick").text());
    let totalPicked = $(
      '.list-drivers .driver-item input[type="checkbox"]:checked'
    ).length;

    if (totalPicked <= maxPick) {
      $("#TotalDrivers").text(totalPicked);
    } else {
      $(this).prop("checked", false);
    }

    if ($(this).is(":checked")) {
      $(this).closest(".driver-item").addClass("checkbox-checked");
    } else {
      $(this).closest(".driver-item").removeClass("checkbox-checked");
    }

    let checkedItems = $(".driver-item.checkbox-checked")
      .map(function () {
        return $(this).attr("data-driver-id");
      })
      .get()
      .join(",");

    $(".js-drivers-selected").val(checkedItems);
  });

  $(".show-bottom-modal-btn-actions").click(function (e) {
    e.preventDefault();

    $(".bottom-menu-wrapper").removeClass("not-show");
    $("#CalendarActions").removeClass("not-show");

    $(".bottom-menu-wrapper .bottom-menu-content").animate({ bottom: 0 }, 200);
  });

  $('.close-btn-add-actions').click(function (e) {
    e.preventDefault();

    $(".bottom-menu-wrapper").addClass("not-show");

    $(".bottom-menu-wrapper .bottom-menu-content").css({ bottom: "-500px" });
  });

  $(".prj-menu-short .rdbox").click(function (e) {
    e.preventDefault();

    $(".prj-menu-short .rdbox").removeClass("selected");
    $('.prj-menu-short .rdbox input[name="prj_sort_type"]').removeAttr(
      "checked"
    );

    $(this).addClass("selected");
    $(this).find('input[name="prj_sort_type"]').attr("checked", "checked");
  });

  $('#agency_select').change(function() {
    let agencyId = $(this).val();
    $('#driver_select').html([]);
    updateDriverDropdown(agencyId);
  });

  function updateDriverDropdown(agencyId) {
    $.ajax({
      url: `/agencies/${agencyId}/agent-drivers`,
      type: 'GET',
      dataType: 'json',
      success: function(data) {
        let options = '';
        $.each(data, function(index, driver) {
          let selected = index === 0 ? 'selected="selected"' : '';
          options += '<option ' + selected +' value="' + driver.id + '">' + driver.name + '</option>';
        });
        $('#driver_select').html(options);
      }
    });
  }  
});

function hideRemoveElement (className, number) {
  let elements = document.getElementsByClassName(className);
  if (elements.length > 0) {
    elements[number].classList.add("inactive");
  }
}

$(document).on("change", "#price-type-cb", function () {
  $("#project-day-salary").toggleClass("d-none");
  $("#project-parcels-salary").toggleClass("d-none");
});

$(document).on('change', '#project_supplier_id', function() {
  getDataSupplier(this);
});

function getDataSupplier(element) {
  let selectedValue = $(element).val();
  let host = window.location.protocol + "//" + window.location.host;
  $.ajax({
    url: host + '/admin/get_person_charge_options',
    type: 'GET',
    dataType: 'json',
    data: { supplier_id: selectedValue },
    success: function(data) {
      let options = '';
      $.each(data, function(index, value) {
        options += '<option value="' + value.id + '">' + value.name + '</option>';
      });
      $('#child_select_container').html('<select id="child_select" class="form-control form-custom">' + options + '</select>');
    }
  });
}
