function handleFileInput(fileInputId, previewContainerId) {
  const fileInput = document.getElementById(fileInputId);
  const previewContainer = document.getElementById(previewContainerId);

  previewContainer.innerHTML = "";

  for (let i = 0; i < fileInput.files.length; i++) {
    const file = fileInput.files[i];

    const fileElement = file.type === "application/pdf"
      ? `<embed src="${window.URL.createObjectURL(file)}" class="col" />`
      : `<img src="${window.URL.createObjectURL(file)}" class="col" />`;

    previewContainer.insertAdjacentHTML("beforeend", fileElement);
  }
}

$(document).on("change", "#vehicle_inspection_certificates", function () {
  handleFileInput("vehicle_inspection_certificates", "vehicle_inspection_certificates_preview");
});

$(document).on("change", "#vehicle_insurance_certificates", function () {
  handleFileInput("vehicle_insurance_certificates", "vehicle_insurance_certificates_preview");
});

$(document).on("change", "#vehicle_liability_insurances", function () {
  handleFileInput("vehicle_liability_insurances", "vehicle_liability_insurances_preview");
});

$(document).on("change", "#vehicle_vehicle_delivery_images", function () {
  handleFileInput("vehicle_vehicle_delivery_images", "vehicle_delivery_images_preview");
});

$(document).on("change", "#vehicle_last_oil_change_receipt", function () {
  handleFileInput("vehicle_last_oil_change_receipt", "vehicle_last_oil_change_receipt_preview");
});

$(document).on("change", "#service_history_receipt", function () {
  handleFileInput("service_history_receipt", "service_history_receipt_preview");
});

function setupImageModal(classSelector) {
  $(classSelector).click(function(e) {
    e.preventDefault();
    let imageUrl = $(this).find('img').attr('src');
    $('#imageModal .modal-body').html('<img src="' + imageUrl + '" class="img-fluid">');
    $('#imageModal').modal('show');
  });
}

$(document).ready(function() {
  setupImageModal('.info-vehicle-img a');
  setupImageModal('.inspection-certificate .image');
  setupImageModal('.insurance-certificate .image');
  setupImageModal('.liability-insurance .image');
  setupImageModal('.preview-area .image');
  setupInputGroup();
});

function setupInputGroup() {
  $('.input-group-preview').each(function () {
    const $previewGroup = $(this);
    const $fileInput = $previewGroup.find('.form-control-file');
    const $fileLabel = $previewGroup.find('.form-control');
    const $previewArea = $previewGroup.find('.preview-area');
    let filesArray = [];

    $fileInput.on('change', function (event) {
      filesArray = Array.from(event.target.files);
      updatePreview();
    });

    function updatePreview() {
      $previewArea.empty();
      const fileNames =
          filesArray.length > 0 ? $.map(filesArray, file => file.name).join(', ') : 'ファイルが選択されていません';
      $fileLabel.val(fileNames);

      filesArray.forEach((file, index) => {
        const reader = new FileReader();
        reader.onload = function (e) {
          const $previewItem = $('<div>', {
            'class': 'rounded bg-gray my-2 p-1 px-2 mx-1 d-flex'
          });

          const $link = $('<a>', {
            'href': e.target.result,
            'target': '_blank',
            'text': file.name,
            'class': 'col text-truncate'
          });

          const $removeButton = $('<div>', {
            'class': 'btn btn-sm remove-preview',
            'data-index': index,
            'html': '<img src="/assets/icon/x_circle_fill.svg">'
          });

          $previewItem.append($link).append($removeButton);
          $previewArea.append($previewItem);
        };
        reader.readAsDataURL(file);
      });
    }

    $previewArea.on('click', '.remove-preview', function () {
      const index = $(this).data('index');
      filesArray.splice(index, 1);
      updateFileInput();
      updatePreview();
    });

    function updateFileInput() {
      const dataTransfer = new DataTransfer();
      filesArray.forEach(file => dataTransfer.items.add(file));
      $fileInput[0].files = dataTransfer.files;
    }

    $previewArea.on('click', '.remove-uploaded-file', function () {
      const fileId = $(this).data('file-id');
      $previewArea.find(`input[type="hidden"]`).val(fileId);
      $(this).parent().remove();
    });
  });
}
